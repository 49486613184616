import _objectSpread from "/codebuild/output/src869549317/src/applications/node_modules/react-scripts/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import status from '../constants/actionStatus';
import checkIfAnswerInCorrect from "../common/checkIfAnswerInCorrect";
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    status: status.PENDING,
    savedQuestionsList: []
  };
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case 'GET_TEST_SERIES_SAVED_QUESTIONS':
      return {
        status: status.SUCCESS,
        savedQuestionsList: action.payload.savedQuestions
      };
    case 'QUESTION_RESPONSE_SUCCESS':
      {
        try {
          let questionResponse = action.payload;
          let questions = state.savedQuestionsList.map(question => {
            question = _objectSpread({}, question); // copy
            if (question._id === questionResponse.question) {
              //responded question
              question.givenAnswer = questionResponse.givenAnswer;
              //update status
              question.status = checkIfAnswerInCorrect(question.givenAnswer, question.options, "singleChoice") ? 'CORRECT' : 'INCORRECT';
            }
            return question;
          });
          return {
            status: status.SUCCESS,
            savedQuestionsList: questions
          };
        } catch (e) {
          return state;
        }
      }
    case 'TOGGLE_QUESTION__SAVED_SUCCESS':
      {
        //change state of question from list of questions being shown on screen.
        try {
          let updatedQuestionId = action.payload.questionId;
          let isListView = action.payload.isListView;

          //all questions
          let questions = state.savedQuestionsList.map(question => {
            question = _objectSpread({}, question); // copy
            if (question._id !== updatedQuestionId) {
              return question;
            } else {
              //toggle status.
              question.isSaved = !question.isSaved;
              return question;
            }
          });
          let savedQuestions = [];
          //if list view then we will filter out un saved questions.
          if (isListView && questions) {
            savedQuestions = questions.filter(question => {
              return question.isSaved;
            });
          } else {
            //saved questions
            savedQuestions = state.savedQuestionsList.map(question => {
              question = _objectSpread({}, question); // copy
              if (question._id !== updatedQuestionId) {
                return question;
              } else {
                //toggle status.
                question.isSaved = !question.isSaved;
                return question;
              }
            });
          }
          return {
            status: status.SUCCESS,
            savedQuestionsList: savedQuestions
          };
        } catch (error) {
          console.log('error-------', error);
          return state;
        }
      }
    default:
      return state;
  }
});