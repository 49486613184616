import _objectSpread from "/codebuild/output/src869549317/src/applications/node_modules/react-scripts/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//payment plan results.
import { useEffect, useState } from "react";
import { getPaymentPlanList } from "../services/paymentPlanService";

/**
 * Get result of payment plan
 */
export default function usePaymentPlans() {
  const [paymentPlans, setPaymentPlans] = useState({
    faqs: [],
    plans: [],
    coupons: []
  });
  //get results.
  useEffect(() => {
    getPaymentPlans();
  }, []);
  function getPaymentPlans() {
    //call api
    getPaymentPlanList().then(data => {
      let faqs = data.faqs;
      if (faqs) {
        faqs = faqs.map(faq => {
          faq.answer = faq.answer.replace(/<[^>]+>|&nbsp;/g, '');
          return faq;
        });
      }
      setPaymentPlans({
        faqs: faqs,
        plans: data.plans,
        coupons: data.coupons
      });
    }).catch(error => {
      console.error('Error while fetching plans-->', error);
    });
  }
  return _objectSpread({}, paymentPlans);
}