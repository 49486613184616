import _objectSpread from "/codebuild/output/src869549317/src/applications/node_modules/react-scripts/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import status from '../constants/actionStatus';
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    notification: []
  };
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case 'GET_NOTIFICATION_REQUEST':
      return {
        status: status.PENDING,
        notification: []
      };
    case 'GET_NOTIFICATION_SUCCESS':
      const combinedNotifications = [...state.notification, ...action.notificationList.data];
      const uniqueNotifications = Array.from(new Set(combinedNotifications.map(JSON.stringify))).map(JSON.parse);
      return {
        status: status.SUCCESS,
        notification: uniqueNotifications
      };
    case 'COURSE_ADDED_NOTIFICATION_SUCCESS':
      return _objectSpread(_objectSpread({}, state), {}, {
        notification: [action.notificationRes, ...state.notification]
      });
    case 'GET_NOTIFICATION_FAILURE':
      return {
        status: status.ERROR,
        notification: state.notification
      };
    default:
      return state;
  }
});