import _objectSpread from "/codebuild/output/src869549317/src/applications/node_modules/react-scripts/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import status from "../constants/actionStatus";
const initialState = {
  data: [],
  status: status.PENDING
};
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (action.type === 'GET_ALL_TEST_SUCCESS') {
    return {
      data: action.payload,
      status: status.SUCCESS,
      examId: action.examId,
      lastUpdated: action.lastUpdated
    };
  } else if (action.type === 'GET_ALL_TEST_PENDING' || action.type === 'USER_LANGUAGE_UPDATED') {
    return initialState;
  } else if (action.type === 'GET_ALL_UPDATED_TEST') {
    let olderQuizzes = state.data;
    let updatedQuizzes = action.payload;
    let newQuizData = [...updatedQuizzes];
    if (olderQuizzes && olderQuizzes.length) {
      olderQuizzes.forEach(item => {
        const isDuplicateItem = newQuizData.some(data => data._id === item._id);
        if (!isDuplicateItem) {
          newQuizData.push(item);
        }
      });
    }
    return {
      data: newQuizData,
      status: status.SUCCESS,
      examId: action.examId,
      lastUpdated: action.lastUpdated
    };
  } else if (action.type === 'TOGGLE_QUIZ__SAVED_SUCCESS') {
    //change state of question from list of questions being shown on screen.
    let quizzes = state.data;
    let quizList = quizzes.map(quiz => {
      quiz = _objectSpread({}, quiz); // copy
      if (quiz._id !== action.payload.quizId) {
        return quiz;
      } else {
        //toggle status.
        quiz.isSaved = !quiz.isSaved;
        return quiz;
      }
    });
    quizList = [...quizList];
    return {
      status: status.SUCCESS,
      data: quizList
    };
  }
  return state;
});