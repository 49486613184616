import _objectSpread from "/codebuild/output/src869549317/src/applications/node_modules/react-scripts/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import axios from './axiosConfig';
export function getPracticeQuestionList(query) {
  // api to get all questions saved by user
  return axios.get('/api/v1/practice-questions?subject=' + query.subject + '&topic=' + query.topic).then(response => {
    // handle success
    let questionList = response.data;
    let savedQuestions = [];
    if (questionList && questionList.length) {
      savedQuestions = filterQuestionData(questionList);
    }
    return {
      questionList: questionList,
      savedQuestions: savedQuestions
    };
  }).catch(error => {
    return error;
  });
}
export function getQuesCount() {
  return axios.get('/api/v1/question-count').then(response => {
    //handle success
    return response.data;
  }).catch(error => {
    //handle error
    return error;
  });
}
export function getSavedQuestionList() {
  return axios.get('/api/v1/saved-question-list').then(response => {
    //handle success
    return response.data;
  }).catch(error => {
    //handle error
    return error;
  });
}
export function getFavouriteQuestions(id) {
  return axios.get('/api/v1/fav-ques/' + id).then(response => {
    return response.data;
  }).catch(error => {
    // handle error
    return error;
  });
}
export function saveQuestionResponses(data) {
  return axios.post('/api/v1/question-response', data).then(response => {
    let questionResponse = response.data;
    let path = window.location.pathname; // get current subject id and topic id from Url
    let subjectId = path.split('/')[4];
    let topicId = path.split('/')[6];
    let questionResponseData = _objectSpread(_objectSpread({}, questionResponse), {}, {
      subjectId: subjectId,
      topicId: topicId
    });
    return questionResponseData;
  }).catch(error => {
    // handle error
    return error;
  });
}
export function saveQuestionResponsesMobile(data) {
  return axios.post('/api/v1/question-response', data).then(response => {
    let subjectId = data.subject;
    let topicId = data.topic;
    let questionResponse = response.data;
    let questionResponseData = _objectSpread(_objectSpread({}, questionResponse), {}, {
      subjectId: subjectId,
      topicId: topicId
    });
    return questionResponseData;
  }).catch(error => {
    // handle error
    return error;
  });
}
export const filterQuestionData = questions => {
  return questions ? questions.filter(question => {
    return question.isSaved;
  }) : [];
};