import _objectSpread from "/codebuild/output/src869549317/src/applications/node_modules/react-scripts/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUserCourse } from "examsbook-react-sdk/actions";
import status from "../constants/actionStatus";
import { isUrlAbsolute } from "../common";
import staticUrl from "examsbook-react-sdk/constants/staticUrl";

/**
 * get course detail.
 * @returns {*}
 */
export default function useUserCourse(userId, isRefresh) {
  var _courseData$courses, _courseData$courses$d, _courseData$courses2;
  const dispatch = useDispatch();
  useEffect(() => {
    const ac = new AbortController();
    userId && dispatch(getUserCourse(userId));
    return () => ac.abort();
  }, [isRefresh, userId]);
  let courseData = useSelector(state => state.userCourses);
  let count = courseData && courseData.courses && courseData.courses.count;
  let isLoading = courseData && courseData.status === status.PENDING;
  let userCourses = [];
  let userCoursesData = courseData && (courseData === null || courseData === void 0 ? void 0 : courseData.courses) && (courseData === null || courseData === void 0 ? void 0 : courseData.courses.data) && (courseData === null || courseData === void 0 ? void 0 : (_courseData$courses = courseData.courses) === null || _courseData$courses === void 0 ? void 0 : (_courseData$courses$d = _courseData$courses.data) === null || _courseData$courses$d === void 0 ? void 0 : _courseData$courses$d.length) && (courseData === null || courseData === void 0 ? void 0 : (_courseData$courses2 = courseData.courses) === null || _courseData$courses2 === void 0 ? void 0 : _courseData$courses2.data.forEach(element => {
    let modifiedData = element === null || element === void 0 ? void 0 : element.courses.map((course, idx) => {
      var _course$featured;
      return _objectSpread(_objectSpread(_objectSpread({}, element), element.courses[idx]), {}, {
        featuredMobile: isUrlAbsolute(course === null || course === void 0 ? void 0 : (_course$featured = course.featured) === null || _course$featured === void 0 ? void 0 : _course$featured.thumbnail.jpg) ? course === null || course === void 0 ? void 0 : course.featured.thumbnail.jpg : staticUrl.STAGE_URL + course.featured.thumbnail.jpg
      });
    });
    userCourses = [...userCourses, ...modifiedData];
  }));
  return {
    count,
    isLoading,
    userCourses
  };
}