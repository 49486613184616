import _objectSpread from "/codebuild/output/src869549317/src/applications/node_modules/react-scripts/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import status from '../constants/actionStatus';
const initialState = {
  defaultSeoSettings: [],
  status: null
};
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case 'GET_DEFAULT_SEO_SETTINGS_REQUEST':
      return _objectSpread(_objectSpread({}, state), {}, {
        status: status.PENDING
      });
    case 'GET_DEFAULT_SEO_SETTINGS_SUCCESS':
      return _objectSpread(_objectSpread({}, state), {}, {
        status: status.SUCCESS,
        defaultSeoSettings: action.payload.data
      });
    case 'GET_DEFAULT_SEO_SETTINGS_FAILURE':
      return _objectSpread(_objectSpread({}, state), {}, {
        status: status.ERROR
      });
    default:
      return state;
  }
});