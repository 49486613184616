import _objectSpread from "/codebuild/output/src869549317/src/applications/node_modules/react-scripts/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import status from "../constants/actionStatus";
export default (function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    questions: [],
    totalQuestions: 0,
    id: '',
    status: status.PENDING
  };
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case 'GET_QUIZ_SUCCESS':
      return action.payload;
    case 'UNSAVE_QUESTION_SUCCESS':
      {
        try {
          let updatedQuestionId = action.payload.questionId;
          let quizData = _objectSpread({}, state);
          quizData.questions = quizData.questions.map(quizSectionData => {
            quizSectionData.questions = quizSectionData.questions.map(quizQuestion => {
              quizQuestion = _objectSpread({}, quizQuestion); // copy
              if (quizQuestion.id._id !== updatedQuestionId) {
                return quizQuestion;
              } else {
                //toggle status.
                quizQuestion.isSaved = !quizQuestion.isSaved;
                return quizQuestion;
              }
            });
            return quizSectionData;
          });
          return quizData;
        } catch (error) {
          console.log('error-------', error);
          return state;
        }
      }
    case 'GET_QUIZ_ERROR':
      return action.payload;
    case 'GET_QUIZ_REQUEST':
      return action.payload;
    case 'QUIZ_UPDATED':
      return action.payload;
    case 'QUESTION_ANSWERED':
      return JSON.parse(JSON.stringify(action.payload));
    default:
      return state;
  }
});